import { register } from '@netcentric/component-loader';
import config from './batcom-shareprice.config';

class SharePrice {
  constructor(el, params) {
    this.el = el;
    this.config = { ...config, ...params };
    this.init();
  }

  init() {
    this.setRefs();
    if(this.config.source) {
      this.fetchSharePriceData();
    }
  }

  setRefs() {
    this.currentPrice = this.el.querySelector(this.config.selectors.currentPrice);
    this.change = this.el.querySelector(this.config.selectors.change);
    this.dateTime = this.el.querySelector(this.config.selectors.dateTime);
  }

  async fetchSharePriceData() {
    try {
      const response = await fetch(this.config.source);
      const content = await response.text();
      const sharePriceData = this.parseSharePriceData(content);
      this.updateDOM(sharePriceData);
    } catch (error) { /* empty */ }
  }

  parseSharePriceData(content) {
    const domElement = new DOMParser().parseFromString(content, 'text/html');
    return JSON.parse(domElement.querySelector(`[data-symbol='${this.config.symbol}']`).dataset.item);
  }

  updateDOM(sharePriceData) {
    this.currentPrice.textContent = sharePriceData.CurrentPrice + this.currentPrice.textContent;
    this.change.textContent = sharePriceData.Change + this.change.textContent;
    this.dateTime.textContent = `${sharePriceData.Date} ${sharePriceData.Time}`;
  }
}
register({ SharePrice });
